import { Controller } from '@hotwired/stimulus'

const servicesArray = ['audit', 'design', 'web', 'mobile', 'mvp']
const complexityArray = ['low', 'mid', 'high']
const sizeArray = ['small', 'medium', 'big']
const durationArray = ['gt6', 'bt25', 'ls2']
const PRICING = {
  web: {
    low_small_gt6: '$153K - $307K',
    low_small_bt25: '$51K - $128K',
    low_small_ls2: '$25K - $51K',
    low_medium_gt6: '$230K - $460K',
    low_medium_bt25: '$76K - $192K',
    low_medium_ls2: '$38K - $76K',

    low_big_gt6: '$307K - $614K',
    low_big_bt25: '$102K - $256K',
    low_big_ls2: '$51K - $102K',

    mid_small_gt6: '$230K - $460K',
    mid_small_bt25: '$76K - $192K',
    mid_small_ls2: '$38K - $76K',

    mid_medium_gt6: '$307K - $614K',
    mid_medium_bt25: '$102K - $256K',
    mid_medium_ls2: '$51K - $102K',

    mid_big_gt6: '$384K - $768K',
    mid_big_bt25: '$128K - $320K',
    mid_big_ls2: '$64K - $128K',

    high_small_gt6: '$307K - $614K',
    high_small_bt25: '$102K - $256K',
    high_small_ls2: '$51K - $102K',

    high_medium_gt6: '$384K - $768K',
    high_medium_bt25: '$128K - $320K',
    high_medium_ls2: '$64K - $128K',

    high_big_gt6: '$460K - $921K',
    high_big_bt25: '$153K - $384K',
    high_big_ls2: '$76K - $153K'
  },
  mvp: {
    low_small_gt6: '$161K - $322K',
    low_small_bt25: '$53K - $134K',
    low_small_ls2: '$26K - $53K',

    low_medium_gt6: '$241K - $483K',
    low_medium_bt25: '$80K - $201K',
    low_medium_ls2: '$40K - $80K',

    low_big_gt6: '$403K - $806K',
    low_big_bt25: '$134K - $336K',
    low_big_ls2: '$67K - $134K',

    mid_small_gt6: '$241K - $483K',
    mid_small_bt25: '$80K - $201K',
    mid_small_ls2: '$40K - $80K',

    mid_medium_gt6: '$322K - $645K',
    mid_medium_bt25: '$107K - $268K',
    mid_medium_ls2: '$53K - $107K',

    mid_big_gt6: '$483K - $967K',
    mid_big_bt25: '$161K - $403K',
    mid_big_ls2: '$80K - $161K',

    high_small_gt6: '$322K - $645K',
    high_small_bt25: '$107K - $268K',
    high_small_ls2: '$53K - $107K',

    high_medium_gt6: '$403K - $806K',
    high_medium_bt25: '$134K - $336K',
    high_medium_ls2: '$67K - $134K',

    high_big_gt6: '$564K - $1,128K',
    high_big_bt25: '$188K - $470K',
    high_big_ls2: '$94K - $188K'
  },
  mobile: {
    low_small_gt6: '$168K - $336K',
    low_small_bt25: '$56K - $140K',
    low_small_ls2: '$28K - $56K',

    low_medium_gt6: '$252K - $504K',
    low_medium_bt25: '$84K - $210K',
    low_medium_ls2: '$42K - $84K',

    low_big_gt6: '$420K - $840K',
    low_big_bt25: '$140K - $350K',
    low_big_ls2: '$70K - $140K',

    mid_small_gt6: '$252K - $504K',
    mid_small_bt25: '$84K - $210K',
    mid_small_ls2: '$42K - $84K',

    mid_medium_gt6: '$336K - $672K',
    mid_medium_bt25: '$112K - $280K',
    mid_medium_ls2: '$56K - $112K',

    mid_big_gt6: '$504K - $1,008K',
    mid_big_bt25: '$168K - $420K',
    mid_big_ls2: '$84K - $168K',

    high_small_gt6: '$336K - $672K',
    high_small_bt25: '$112K - $280K',
    high_small_ls2: '$56K - $112K',

    high_medium_gt6: '$420K - $840K',
    high_medium_bt25: '$140K - $350K',
    high_medium_ls2: '$70K - $140K',

    high_big_gt6: '$588K - $1,176K',
    high_big_bt25: '$196K - $490K',
    high_big_ls2: '$98K - $196K'
  },
  audit: {
    low_small_ls2: '$6K - $12K',
    low_medium_ls2: '$9K - $19K',
    low_big_ls2: '$12K - $25K',

    mid_small_ls2: '$8K - $16K',
    mid_medium_ls2: '$12K - $24K',
    mid_big_ls2: '$16K - $32K',

    high_small_ls2: '$9K - $19K',
    high_medium_ls2: '$14K - $28K',
    high_big_ls2: '$19K - $38K'
  },
  design: {
    low_small_ls2: '$6K - $12K',
    low_medium_ls2: '$9K - $19K',
    low_big_ls2: '$12K - $25K',

    mid_small_ls2: '$8K - $16K',
    mid_medium_ls2: '$12K - $24K',
    mid_big_ls2: '$16K - $32K',

    high_small_ls2: '$9K - $19K',
    high_medium_ls2: '$14K - $28K',
    high_big_ls2: '$19K - $38K'
  }
}

export default class Pricing extends Controller {
  static targets = ['total', 'duration', 'service']

  static values = {
    service: { type: String, default: 'web' },
    complexity: { type: String, default: 'low' },
    size: { type: String, default: 'small' },
    duration: { type: String, default: 'ls2' }
  }

  connect () {
    this.serviceTarget.querySelector(`input[value=${this.serviceValue}]`).checked = true

    this.modalTimeout = setTimeout(() => {
      this.dispatch('openModal')
    }, 30000)
  }

  disconnect () {
    clearTimeout(this.modalTimeout)
  }

  serviceValueChanged (value) {
    if (value === 'audit' || value === 'design') {
      this.durationTargets.forEach(input => {
        if (input.value === 'ls2') {
          input.checked = true
        } else {
          input.disabled = true
        }
      })

      this.durationValue = 'ls2'
    } else {
      this.durationTargets.forEach(input => {
        input.disabled = false
      })
    }

    this.calculateBudget()
  }

  complexityValueChanged () {
    this.calculateBudget()
  }

  sizeValueChanged () {
    this.calculateBudget()
  }

  durationValueChanged () {
    this.calculateBudget()
  }

  setData (input) {
    const { name, value } = input.target

    switch (name) {
      case 'service':
        this.serviceValue = value
        break
      case 'complexity':
        this.complexityValue = value
        break
      case 'size':
        this.sizeValue = value
        break
      case 'duration':
        this.durationValue = value
        break
      default:
        console.warn(`Unknown input name: ${name}`)
        break
    }
  }

  calculateBudget () {
    this.checkError()

    const key = [this.complexityValue, this.sizeValue, this.durationValue].join('_')

    this.totalTarget.classList.add('pricing__value-animate')
    this.totalTarget.innerText = PRICING[`${this.serviceValue}`][key]

    setTimeout(() => {
      this.totalTarget.classList.remove('pricing__value-animate')
    }, '1000')
  }

  checkError () {
    if (!servicesArray.includes(this.serviceValue)) {
      throw new Error('You can choose only one service. For example: web, mvp, mobile, audit, design')
    }
    if (!complexityArray.includes(this.complexityValue)) {
      throw new Error('You can choose only one complexity. For example: low, mid or high')
    }
    if (!sizeArray.includes(this.sizeValue)) {
      throw new Error('You can choose only one size. For example: low, medium, big')
    }
    if (!durationArray.includes(this.durationValue)) {
      throw new Error('You can choose only one duration. For example: gt6, bt25 or ls2')
    }
  }
}
