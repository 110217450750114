import { Controller } from '@hotwired/stimulus'

export default class Tabs extends Controller {
  static targets = ['tabButton', 'tabContent']
  static values = { activeButtonClass: String }

  connect () {
    this.currentTabIndex = Number(this.element.dataset.index)
    this.showCurrentTab()
  }

  onButtonClick () {
    this.currentTabIndex = (this.tabButtonTargets.findIndex(tab => tab.id === window.event.currentTarget.id))
    this.showCurrentTab()
  }

  showCurrentTab () {
    this.tabButtonTargets.forEach((element, index) => {
      index === this.currentTabIndex ? element.classList.add(this.activeButtonClassValue) : element.classList.remove(this.activeButtonClassValue)
    })

    this.tabContentTargets.forEach((element, index) => {
      index === this.currentTabIndex ? element.classList.remove('tab__content--hidden') : element.classList.add('tab__content--hidden')
    })
  }
}
