import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider'

export default class PortfolioController extends Controller {
  static targets = ['gallery', 'prev', 'next']

  static values = {
    mode: { type: String, default: 'gallery' }
  }

  connect () {
    this.render()
  }

  render () {
    new tns({
      container: this.galleryTarget,
      mode: this.modeValue,
      items: 1,
      slideBy: 1,
      loop: false,
      swipeAngle: false,
      speed: 300,
      edgePadding: 0,
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',
      nav: false,
      nextButton: this.nextTarget,
      prevButton: this.prevTarget,
      autoplay: true,
      autoplayTimeout: 10000,
      autoplayButtonOutput: false,
      responsive: {
        640: {
          fixedWidth: 400
        }
      }
    })
  }
}
