import { Controller } from '@hotwired/stimulus'

export default class PlausibleController extends Controller {
  event (e) {
    const { target, detail, params } = e
    const { name } = params

    if (target.tagName === 'FORM') {
      if (detail.success === true) {
        window.plausible(`Submit ${name} Success`)
      } else {
        window.plausible(`Submit ${name} Error`)
      }
    } else {
      window.plausible(name)
    }
  }
}
