import { Application } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { registerControllers } from 'stimulus-vite-helpers'
import { Alert, Tabs, Toggle } from 'tailwindcss-stimulus-components'

const application = Application.start()
const controllers = import.meta.glob('~/application_controllers/**/*_controller.js', { eager: true })

application.register('alert', Alert)
application.register('ttabs', Tabs)
application.register('toggle', Toggle)

window.Stimulus = application
registerControllers(application, controllers)

Turbo.setProgressBarDelay(500)

document.addEventListener('turbo:load', function () {
  window.CLUTCHCO && window.CLUTCHCO.Init()
})
