import { Controller } from '@hotwired/stimulus'

export default class Video extends Controller {
  static targets = ['source']

  connect () {
    if ('IntersectionObserver' in window) {
      var lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (const source in video.target.children) {
              const videoSource = video.target.children[source]
              if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
                videoSource.src = videoSource.dataset.src
              }
            }

            video.target.load()
            video.target.classList.remove('lazy')
            lazyVideoObserver.unobserve(video.target)
          }
        })
      })

      lazyVideoObserver.observe(this.element)
    }
  }
}
