import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider'
export default class SliderController extends Controller {
  static targets = ['carousel', 'next', 'prev']

  static values = {
    mode: { type: String, default: 'gallery' },
    nav: { type: Boolean, default: true }
  }

  connect () {
    this.render()
  }

  render () {
    new tns({
      container: this.carouselTarget,
      items: 1,
      mode: this.modeValue,
      center: true,
      nav: true,
      nextButton: this.nextTarget,
      prevButton: this.prevTarget,
      controls: !this.navValue,
      responsive: {
        550: {
          controls: true
        },
        768: {
          nav: this.navValue
        }
      }
    })
  }
}
