import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider'
export default class CareersController extends Controller {
  static targets = ['carousel', 'next', 'prev']

  static values = {
    mode: { type: String, default: 'gallery' }
  }

  connect () {
    this.render()
  }

  render () {
    new tns({
      container: this.carouselTarget,
      items: 2,
      mode: this.modeValue,
      center: false,
      nav: false,
      autoWidth: false,
      slideBy: 2,
      gutter: 16,
      autoplay: true,
      autoplayButtonOutput: false,
      controls: false,
      loop: true,
      rewind: false,
      speed: 3500,
      mouseDrag: true,
      autoplayTimeout: 4500,
      responsive: {
        768: {
          gutter: 32,
          items: 3,
          slideBy: 1,
          center: true,
          autoplayTimeout: 4500,
          speed: 3500
        }
      }
    })
  }
}
