import { Controller } from '@hotwired/stimulus'

export default class Accordion extends Controller {
  static targets = ['accordion']

  manageAccordionState () {
    this.notClickedBlocks = this.accordionTargets.filter(
      accordionBlock => accordionBlock.id !== window.event.currentTarget.id
    )
    this.notClickedBlocks.forEach(notClickedBlock => notClickedBlock.classList.remove('accordion__block--open'))
    window.event.currentTarget.classList.toggle('accordion__block--open')
  }
}
