import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider'

export default class PortfolioController extends Controller {
  static targets = ['carousel']

  static values = {
    mode: { type: String, default: 'gallery' }
  }

  connect () {
    this.render()
  }

  render () {
    new tns({
      items: 1,
      slideBy: 1,
      gutter: 16,
      speed: 3500,
      autoplayTimeout: 9000,
      container: this.carouselTarget,
      mode: this.modeValue,
      center: true,
      controls: false,
      nav: true,
      navAsThumbnails: true,
      autoplay: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      responsive: {
        320: {
          fixedWidth: 360
        },
        375: {
          fixedWidth: 410
        },
        640: {
          fixedWidth: 550
        },
        768: {
          gutter: 32,
          fixedWidth: 960
        }
      }
    })
  }
}
